import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import AceEditor from 'react-ace';
import 'brace/mode/json'; // Import the JSON mode
import 'brace/theme/monokai'; // Import the Monokai theme

import { IconTabs, Typography } from '@/components';
import { CONFIG_TABS } from '@/utils/constants';
import { useMainStore } from '../../stores/useMainStore';

import styles from './DetailWorkflowContent.module.css';
import { getWorkflowAttachments } from '@/utils/staticForm';

const JSON_LINE_HEIGHT = 17;
const JSON_EDITOR_PADDING = 28;
const tabs = [
  { label: CONFIG_TABS.form, value: true },
  { label: CONFIG_TABS.json, value: false },
];

export const DetailWorkflowContent = observer(({ content, isEditing, workflowName }) => {
  const { workflowStore } = useMainStore();
  const [SpecificForm, setSpecificForm] = useState(null);
  const jsonStr = JSON.stringify(content, null, 2);
  const height = `${jsonStr.split('\n').length * JSON_LINE_HEIGHT + JSON_EDITOR_PADDING}px`;
  const showForm = workflowStore.showForm;

  useEffect(() => {
    if (workflowName) {
      import(`./SpecificForms/${workflowName}`)
        .then((module) => {
          setSpecificForm(() => module[workflowName]);
        })
        .catch(() => {
          setSpecificForm(null);
        });
      const configAttachments = getWorkflowAttachments(workflowName, content);
      workflowStore.reset();
      workflowStore.setSkipExecution(content?.SkipExecution);
      workflowStore.setConfigAttachments(configAttachments);
    }
  }, [workflowName]);

  return (
    <>
      <div className={styles.header}>
        <Typography variant="caption-bold">Configuration</Typography>
        <IconTabs
          activeColor=""
          tabArray={tabs}
          value={showForm}
          flexContainer={styles.row}
          onChange={(_, newValue) => workflowStore.setShowForm(newValue)}
        />
      </div>
      {showForm && Boolean(SpecificForm) ? (
        <SpecificForm config={content} disabled={!isEditing} showWarning />
      ) : (
        <></>
      )}
      {!showForm && (
        <AceEditor
          mode="json"
          theme="monokai"
          readOnly
          name="json-editor"
          value={jsonStr}
          editorProps={{ $blockScrolling: true }}
          showPrintMargin={false}
          showGutter={false}
          highlightActiveLine
          fontSize={14}
          height={height}
          style={{ borderRadius: '4px', width: 'calc(100% - 4px)' }}
          enableBasicAutocompletion={false}
          enableLiveAutocompletion={false}
          enableSnippets={false}
          tabSize={2}
        />
      )}
    </>
  );
});
