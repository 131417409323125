import { ColumnType } from '@/utils/types';
import { useEffect } from 'react';

export function useTicketDetails(taskLogStore, detailStore, selectedTicketId) {
  const showTicketDetails = (ticketId) => {
    if (ticketId && taskLogStore.parsedLogs) {
      const ticketLog = taskLogStore.parsedLogs.find((log) => log[ColumnType.id] === ticketId);
      if (ticketLog) {
        detailStore.setSelectedTicket(ticketLog);
        detailStore.isShowTicket = true;
      } else {
        clearTicketSelection();
      }
    } else {
      clearTicketSelection();
    }
  };

  const clearTicketSelection = () => {
    detailStore.setSelectedTicket(null);
    detailStore.isShowTicket = false;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const ticketId = Number(searchParams.get('ticketId'));

    showTicketDetails(ticketId);
  }, [taskLogStore.parsedLogs, selectedTicketId]);
}
