import { makeStyles } from '@mui/styles';

import Typography from '../Typography';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  infoRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    paddingRight: '12px',
    borderRight: '1px solid #E0E0E0',
  },
  padding: {
    display: 'flex',
    paddingLeft: '16px',
  },
}));

export const CompactInfo = ({ label, children, variant = 'body2', leftIndent = false }) => {
  const styles = useStyles();
  return (
    <div className={clsx(styles.infoRow, { [styles.item]: !leftIndent })}>
      <Typography variant={variant}>{label}</Typography>
      <div className={leftIndent && styles.padding}>{children}</div>
    </div>
  );
};
