import { observer } from 'mobx-react-lite';
import { useRef, useState, useEffect } from 'react';
import { withSize } from 'react-sizeme';
import cx from 'clsx';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { ListItemText } from '@mui/material';

import { useMainStore } from '../../stores/useMainStore';
import { cloneDeep } from 'lodash';
import {
  formatLocalDateTime,
} from '@/utils';
import { RequestTypes } from '@/utils/types';
import { FilterType } from '@/utils/constants';
import {
  Checkbox,
  DateTimePicker,
  MultiRadioDropdown,
  MultiSelect,
  Switch,
  Typography,
} from '@/components';

import styles from './FilterPanel.module.css';

const isMobile = false;
const SCROLL_STATE = {
  none: 0,
  left: 1,
  middle: 2,
  right: 3,
};
const PAGE_NAME = 'TaskLog_FilterPanel';

export const FilterPanel = withSize()(
  observer(({ size, vertical, localFilters, setLocalFilters }) => {
    const { filterStore } = useMainStore();
    const [scrollState, setScrollState] = useState(SCROLL_STATE.none);
    const [scrollChanged, setScrollChanged] = useState(false);
    const filterContainerRef = useRef(null);

    useEffect(() => {
      if (!filterContainerRef.current || vertical) return;

      const offsetX = filterContainerRef.current.scrollLeft;
      const clientWidth = size.width;
      const scrollWidth = filterContainerRef.current.scrollWidth;

      if (clientWidth < scrollWidth) {
        if (offsetX === 0) {
          setScrollState(SCROLL_STATE.right);
        } else if (offsetX > 0 && offsetX + clientWidth < scrollWidth) {
          setScrollState(SCROLL_STATE.middle);
        } else if (offsetX + clientWidth >= scrollWidth) {
          setScrollState(SCROLL_STATE.left);
        }
      } else {
        setScrollState(SCROLL_STATE.none);
      }
      // eslint-disable-next-line
    }, [size, scrollChanged]);

    const handleSelectedValueChange = (filterKey, value) => {
      const newFilters = cloneDeep(filterStore.filters);
      newFilters[filterKey].selectedValues = value;

      setLocalFilters(newFilters);
    };

    const handleMoveLeftScroll = (e) => {
      if (isMobile) {
        filterContainerRef.current.scrollTo({
          left: Math.min(filterContainerRef.current.scrollLeft - 216, 0),
          behavior: 'smooth',
        });
        setTimeout(() => setScrollChanged((prev) => !prev), 200);
      } else {
        filterContainerRef.current.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
        setScrollState(SCROLL_STATE.right);
      }
    };

    const handleMoveRightScroll = (e) => {
      if (isMobile) {
        const left = Math.min(
          filterContainerRef.current.scrollLeft + 216,
          filterContainerRef.current.scrollWidth - size.width,
        );
        filterContainerRef.current.scrollTo({ left, behavior: 'smooth' });
        setTimeout(() => setScrollChanged((prev) => !prev), 200);
      } else {
        const left = filterContainerRef.current.scrollWidth - size.width;
        filterContainerRef.current.scrollTo({ left, behavior: 'smooth' });
        setScrollState(SCROLL_STATE.left);
      }
    };

    return (
      <div
        className={cx(styles.filtersGroup, { [styles.verticalFiltersGroup]: vertical })}
        ref={filterContainerRef}
        id={`${PAGE_NAME}_container`}
      >
        {!vertical &&
          (scrollState === SCROLL_STATE.middle || scrollState === SCROLL_STATE.left) && (
            <div id={`${PAGE_NAME}_leftShadow`} className={styles.leftShadow}>
              <div
                className={cx(styles.arrowIcon, styles.leftArrowIcon)}
                onClick={handleMoveLeftScroll}
              >
                <ChevronLeft />
              </div>
            </div>
          )}
        {Object.keys(localFilters).map((filterKey) => {
          const {
            label,
            selectedValues: selectedValue,
            values,
            hasSearchBar,
            hasSelectAllClear,
          } = localFilters[filterKey];
          const options = values.map((item) => ({
            value: item.value,
            label: item.label,
            type: item.type,
          }));

          if (filterKey === FilterType.type) {
            return (
              <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_dropdown`}>
                <MultiRadioDropdown
                  id={`service-filter-${filterKey}`}
                  key={filterKey}
                  value={selectedValue}
                  defaultValue={RequestTypes.all}
                  placeholder={label}
                  options={options}
                  classes={{ root: vertical ? styles.verticalItem : null }}
                  onChange={(value) => handleSelectedValueChange(filterKey, value)}
                />
              </div>
            );
          }

          if (filterKey === FilterType.updatedAfter) {
            return (
              <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_updatedAfter`}>
                <DateTimePicker
                  isDate
                  isSmallSize
                  placeholder={label}
                  className={styles.dateFilter}
                  value={selectedValue?.[0]}
                  maxDate={Date.now()}
                  onChange={(value) =>
                    handleSelectedValueChange(filterKey, [formatLocalDateTime(value, 'MM/DD/YYYY')])
                  }
                />
              </div>
            );
          }

          return (
            <div key={filterKey} className={styles.filterItem} id={`${PAGE_NAME}_dropdown`}>
              <MultiSelect
                id={`btn-filter-${filterKey}`}
                key={filterKey}
                value={selectedValue}
                placeholder={label}
                options={options}
                isSearchBar={hasSearchBar}
                hasSelectAllClear={hasSelectAllClear}
                classes={{ root: vertical ? styles.verticalItem : null }}
                renderValue={(selected, placeholder) => {
                  if (selected.length === 0 && placeholder) {
                    return <Typography variant="body2">{placeholder}</Typography>;
                  }
                  const selectedLabel =
                    options.find(({ value }) => value === selected?.[0])?.label || '';
                  const label = `${selectedLabel.slice(0, 20)}${
                    selectedLabel.length > 20 ? '...' : ''
                  }`;
                  if (selected.length > 1) {
                    return `${label} +${selected.length - 1} more`;
                  }
                  return label;
                }}
                onChange={(value) => handleSelectedValueChange(filterKey, value)}
                MenuItemComponent={({ label, checked, match }) => {
                  if (filterKey === FilterType.viewBy) {
                    return (
                      <>
                        <Switch checked={checked} id={`${PAGE_NAME}_switchItem`} />
                        <ListItemText>{label}</ListItemText>
                      </>
                    );
                  }
                  return (
                    <>
                      <Checkbox
                        checked={checked}
                        id={`${PAGE_NAME}_checkboxItem`}
                        classes={{ formRoot: styles.menuItemCheckbox }}
                      />
                      <span>
                        {match ? (
                          <>
                            {label.slice(0, match[0])}
                            <span className={styles.highlight}>
                              {label.slice(match[0], match[1])}
                            </span>
                            {label.slice(match[1])}
                          </>
                        ) : (
                          label
                        )}
                      </span>
                    </>
                  );
                }}
              />
            </div>
          );
        })}
        {!vertical &&
          (scrollState === SCROLL_STATE.middle || scrollState === SCROLL_STATE.right) && (
            <div id={`${PAGE_NAME}_rightShadow`} className={styles.rightShadow}>
              <div
                className={cx(styles.arrowIcon, styles.rightArrowIcon)}
                onClick={handleMoveRightScroll}
              >
                <ChevronRight />
              </div>
            </div>
          )}
      </div>
    );
  }),
);
