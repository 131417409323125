import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DeleteForeverOutlined, SystemUpdateAlt } from '@mui/icons-material';
import { downloadFile } from '@/utils';
import { SaveAltIcon } from '@/assets/icons';
import LoadingIndicator from '../LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  attachedFiles: {
    position: 'relative',
    borderRadius: 8,
    background: '#fff',
    padding: '8px 12px',
  },
  attachment: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  imageContainer: {
    position: 'relative',
    height: '24px',
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  configIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.neutrals.tableLightGray,
  },
  imgPreview: {
    width: 22,
    height: 22,
    border: '1px solid #E9EDF0',
    borderRadius: 8,
  },
  imgDefault: {
    width: 22,
    height: 22,
  },
  storybookPreview: {
    width: 64,
    height: 64,
    border: '1px solid #E9EDF0',
    borderRadius: 12,
  },
  deleteFileIcon: {
    position: 'absolute',
    right: -8,
    top: -8,
    zIndex: 2,
    cursor: 'pointer',
    color: '#929398 !important',
    '&:hover': {
      color: `${theme.palette.secondary.darkBlue} !important`,
    },
  },
  fileName: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: theme.palette.primary.purple,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 'fit-content',
  },
  deleteFile: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 2,
    width: 65,
    height: 64,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '13px',
  },
  uploadingFile: {
    maxWidth: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  uploadingLabel: {
    fontWeight: 600,
    fontSize: '14px',
    color: theme.palette.secondary.darkBlue,
    whiteSpace: 'nowrap',
  },
  hoveredFile: {
    position: 'absolute',
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    width: 24,
    height: 24,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  downloadIcon: {
    color: '#fff',
  },
}));

const PAGE_NAME = 'AttachmentArea';
const MAX_WORDS = 25;

export function AttachmentArea({
  files,
  configFiles,
  addingFiles,
  deletingFiles,
  onDeleteFile,
  containerClassName,
}) {
  const styles = useStyles();
  const [hoveredId, setHoveredId] = useState(null);
  const allFiles = (files || []).concat(configFiles || []);
  const isEditing = Boolean(onDeleteFile);

  const firstWords = (name) => {
    if (name.length >= MAX_WORDS) return name.substring(0, MAX_WORDS / 2);
    return name;
  };
  const secondWords = (name) => {
    if (name.length >= MAX_WORDS) return `...${name.substring(name.length + 3 - MAX_WORDS / 2)}`;
    return null;
  };

  const handleDownloadFile = (file) => {
    if (!file.id) {
      window.open(file.preview, '_blank');
    } else {
      downloadFile(file);
    }
  };

  return (
    <Grid container className={cx(styles.attachedFiles, containerClassName)}>
      {allFiles.map((file) => (
        <Grid key={file.id} item xs={12}>
          <div id={`${PAGE_NAME}_AttachedFiles_fileInfo`} className={styles.attachment}>
            <div className={styles.imageContainer} onClick={() => handleDownloadFile(file)}>
              {isEditing ? (
                <DeleteForeverOutlined
                  fontSize="small"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteFile(file);
                  }}
                />
              ) : (
                <SystemUpdateAlt fontSize="small" color="info" />
              )}

              {isEditing &&
                ((file.id && hoveredId === file.id) || (!file.id && hoveredId === file.name)) && (
                  <div className={styles.hoveredFile}>
                    <SaveAltIcon className={styles.downloadIcon} />
                  </div>
                )}
            </div>
            <span
              className={styles.fileName}
              onMouseEnter={() => setHoveredId(file.id || file.name)}
              onMouseLeave={() => setHoveredId(null)}
              onClick={() => handleDownloadFile(file)}
            >
              {file.name}
            </span>
            {deletingFiles?.find((item) => item.id === file.id) && (
              <div className={styles.deleteFile}>Deleting..</div>
            )}
          </div>
        </Grid>
      ))}

      {addingFiles?.map((addingFile, index) => (
        <Grid item xs={12} key={`${addingFile.name}_${index}`} className={styles.attachment}>
          <div id={`${PAGE_NAME}_addedFile`} className={styles.uploadingFile}>
            <LoadingIndicator width={60} />
            <span className={styles.uploadingLabel}>{firstWords(addingFile.name)}</span>
            {secondWords(addingFile.name) && (
              <span className={styles.uploadingLabel}>{secondWords(addingFile.name)}</span>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

AttachmentArea.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  configFiles: PropTypes.arrayOf(PropTypes.shape({})),
  addingFiles: PropTypes.arrayOf(PropTypes.shape({})),
  deletingFiles: PropTypes.arrayOf(PropTypes.shape({})),
  onDeleteFile: PropTypes.func,
};

AttachmentArea.defaultProps = {
  files: [],
  configFiles: [],
  addingFiles: [],
  deletingFiles: [],
  onDeleteFile: undefined,
};
