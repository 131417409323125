import { useCallback, useEffect, useState } from 'react';
import { debounce, cloneDeep } from 'lodash';
import { observer } from 'mobx-react-lite';
import { SystemUpdateAlt } from '@mui/icons-material';

import { useMainStore } from '../../stores/useMainStore';
import { FilterPanel } from './FilterPanel';
import { Button, IconButton, MoreButtonPopover, SearchField, Typography } from '@/components';
import { getFiltersQuery, getLogsEndpointURL, showErrorNotification } from '@/utils';
import { Config } from '@/config';
import { userStore } from '@/store';
import styles from './Filter.module.css';
import { MAX_QUERY_LENGTH } from '@/utils/constants';

const PAGE_NAME = 'TaskLog_Filter';

export const Filter = observer(() => {
  const { filterStore, taskLogStore, tableStore } = useMainStore();

  const [localFilters, setLocalFilters] = useState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const tooglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClose = () => {
    handleApplyFilters();
  };

  const handleApplyFilters = () => {
    const query = getFiltersQuery(
      tableStore.pageNumber,
      tableStore.rowsPerPage,
      filterStore,
      filterStore.search,
      filterStore.hasIssuesFilter,
    );
    const sort = { key: tableStore.sortKey, dir: tableStore.sortDirection };
    const url = getLogsEndpointURL(
      `${Config.NEW_API}/Ticket/log`,
      userStore.organizationId,
      query,
      sort,
    );
    if (url.length < MAX_QUERY_LENGTH) {
      filterStore.updateFilterSelectedValues(localFilters);
    } else {
      showErrorNotification("You can't select too many filters");
    }
  };

  const handleResetFilters = () => {
    filterStore.clearFilter();
    setLocalFilters(cloneDeep(filterStore.filters));
    tooglePopover();
  };

  const searchChangeHandler = (event) => {
    filterStore.setSearch(event.target.value.trim());
  };

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(debounce(searchChangeHandler, 500), []);

  useEffect(() => {
    if (filterStore.filteredResult) setLocalFilters(cloneDeep(filterStore.filters));
    // eslint-disable-next-line
  }, [filterStore.filteredResult]);

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.filtersRoot}>
      <div className={styles.filtersContainer}>
        <div className={styles.filterButtons} id={`${PAGE_NAME}_buttonGroupContainer`}>
          <SearchField
            id="txt-log-search"
            placeholder="Search by id or title"
            value={filterStore.search}
            classes={{ root: styles.searchRoot }}
            onChange={debouncedChangeHandler}
          />
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <IconButton
          id="btn-export-csv"
          size="small"
          tooltipArrow
          tooltipTitle="Export the tickets to CSV"
          onClick={() => taskLogStore.exportToCSV()}
        >
          <SystemUpdateAlt fontSize="small" color="info" />
        </IconButton>
        <MoreButtonPopover
          tooltipText="Manage Filters"
          onClose={handleClose}
          isPopoverOpen={isPopoverOpen}
        >
          <div className={styles.popoverHeader}>
            <Typography variant="subtitle1">Manage Filters</Typography>
            {!filterStore.getDefaultStatusSelected && (
              <Button
                id="btn-clear-filter"
                size="small"
                variant="tertiary"
                onClick={handleResetFilters}
              >
                Reset
              </Button>
            )}
          </div>
          <FilterPanel vertical localFilters={localFilters} setLocalFilters={setLocalFilters} />
        </MoreButtonPopover>
      </div>
    </div>
  );
});
