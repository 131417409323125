import { observer } from 'mobx-react-lite';

import { SubmitEdit, Typography } from '@/components';
import { isCreatedByYou } from '@/utils';
import { ObjectType } from '@/utils/types';
import { CommentItem } from './CommentItem';
import { useMainStore } from '../../stores/useMainStore';

import styles from './CommentContent.module.css';

const PAGE_NAME = 'CommentContent';

export const CommentContent = observer(() => {
  const { detailStore } = useMainStore();

  return (
    <>
      {detailStore.objectType !== ObjectType.case && (
        <div className={styles.actionButtonWrapper}>
          <SubmitEdit
            id={`${PAGE_NAME}_submitEdit`}
            title={detailStore.draftComment.title}
            attachments={detailStore.draftComment.attachments}
            onSubmit={(comment, addedFiles) =>
              detailStore.addComment(ObjectType.request, detailStore.ticketId, comment, addedFiles)
            }
            placeholder="Add a Comment"
            isBorder
            required="one"
            assignTo={null}
            updateTitle={(title) => detailStore.updateDraftComment(title)}
            updateAttachments={(attachments) => detailStore.updateDraftComment(null, attachments)}
          >
            Send
          </SubmitEdit>
        </div>
      )}
      <div id={`${PAGE_NAME}_commentsContainer`} className={styles.content}>
        {detailStore.discussions.map((comment) => (
          <CommentItem
            id={`${PAGE_NAME}_commentItem`}
            comment={comment}
            key={comment.id}
            createdByYou={isCreatedByYou(comment.assign.id)}
            handleCommentUpdate={(text, addingFiles, deletingFiles) =>
              detailStore.updateComment(comment.id, text, addingFiles, deletingFiles)
            }
            handleCommentDelete={() => detailStore.deleteComment(comment.id, comment.attachments)}
          />
        ))}
      </div>
    </>
  );
});
