import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import cx from 'clsx';
import { useStyles } from './sharedStyles';
import Tooltip from '../Tooltip';

export const ConfigCell = ({ onShowConfig }) => {
  const styles = useStyles();

  return (
    <TableCell
      key="pin"
      id="ConfigCell"
      align="center"
      className={cx(styles.bodyCell, styles.pinCell)}
      onClick={onShowConfig}
    >
      <Tooltip title="View Ticket Content">
        <div className={styles.configWrapper}>👁</div>
      </Tooltip>
    </TableCell>
  );
};

ConfigCell.propTypes = {
  onShowConfig: PropTypes.func.isRequired,
};
