import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';

import { Button, LoadingIndicator, ResizeGroupPanel, Typography } from '@/components';
import { InformationPanel } from '../InformationPanel';
import { useMainStore } from '../../stores/useMainStore';
import { DetailWorkflowContent } from '../DetailWorkflowContent';
import ScheduleTable from '../ScheduleTable';
import TriggersTable from '../TriggersTable';
import { TICKET_TABS } from '@/utils/constants';
import { TICKET_DETAIL_RESIZE_SAVE_KEY } from '@/utils';
import { CommentContent } from '../CommentContent';
import { Config } from '@/config';
import { AutomationLog } from '@/components/Table/AutomationLog';
import { BASE_ROUTES, ObjectType, UrlSearch } from '@/utils/types';

import styles from './DetailTicketContent.module.css';

const PAGE_NAME = 'DetailTicketContent';

export const DetailTicketContent = observer(() => {
  const [data, setData] = useState({});
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const { detailStore } = useMainStore();
  const ticket = detailStore.parsedTicket;
  const open = detailStore.isShowTicket;
  const { ticketId, title, workflow, organization, organizationId, automation, objectType } = data;

  useEffect(() => {
    if (open) {
      setData(ticket ?? {});
    }
  }, [open, ticket]);

  const updateData = useCallback(
    (fieldValue) => setData((prev) => ({ ...prev, ...fieldValue })),
    [],
  );
  const onCancel = useCallback(() => setData(ticket), [ticket]);

  const handleSaveClicked = async () => {
    if (!detailStore.isEditing) {
      detailStore.setIsEditing(true);
    } else {
      setSaveButtonClicked(true);
      const { title, estimatedCompletion, objectType, remainingWork } = data;
      if (
        (objectType === ObjectType.question && title) ||
        (title && (objectType === ObjectType.task || remainingWork) && estimatedCompletion)
      ) {
        setSaveButtonClicked(false);
        detailStore.setIsEditing(false);
        detailStore.updateTicket(data);
      }
    }
  };

  if (!open) return null;

  return (
    <div
      id={`${PAGE_NAME}_container`}
      className={cx(styles.ticketContentContainer, {
        [styles.isFullScreen]: detailStore.isFullScreen,
      })}
    >
      {detailStore.isLoading && <LoadingIndicator />}
      {!detailStore.isLoading && (
        <>
          <div className={styles.header}>
            <div className={styles.titleWrapper}>
              <Typography variant="subtitle1">
                <a
                  href={`${Config.PORTAL_APP_LINK}/detail?ticket=${ticketId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {ticketId}
                </a>
              </Typography>
              <Typography variant="subtitle1" className={styles.truncatedText}>
                {title}
              </Typography>
              <AutomationLog automation={automation} isAdminUser />
            </div>
            <div id={`${PAGE_NAME}_header`} className={styles.buttonsGroup}>
              <Button
                id={`${PAGE_NAME}_save_button`}
                size="small"
                variant="secondary"
                onClick={handleSaveClicked}
              >
                {detailStore.isEditing ? 'Save' : 'Edit'}
              </Button>
              {detailStore.isEditing && (
                <Button
                  id={`${PAGE_NAME}_cancel_button`}
                  size="small"
                  variant="tertiary"
                  onClick={() => {
                    onCancel();
                    detailStore.setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
          <div className={styles.tabHeader}>
            <Typography variant="subtitle2">{workflow}</Typography>
            <Typography variant="body2">
              <a
                href={`${BASE_ROUTES.organizations}?${UrlSearch.organization}=${organizationId}`}
                target="_blank"
                rel="noreferrer"
              >
                {organization}
              </a>
            </Typography>
          </div>
          {Boolean(ticketId) && (
            <div className={styles.taskContent}>
              <ResizeGroupPanel
                saveId={TICKET_DETAIL_RESIZE_SAVE_KEY}
                direction="horizontal"
                firstPanel={
                  <>
                    <InformationPanel
                      variant={objectType}
                      data={data}
                      updateData={updateData}
                      showError={saveButtonClicked}
                    />
                    <DetailWorkflowContent
                      content={data.config}
                      workflowName={detailStore.workflowConfig}
                      isEditing={detailStore.isEditing}
                    />
                    <CommentContent />
                  </>
                }
                showFirstPanel={!detailStore.isFullWidth}
                className={styles.fitContent}
              >
                <div className={styles.contentWrapper}>
                  {detailStore.isSelectedTab(TICKET_TABS.schedules) && (
                    <>
                      <ScheduleTable />
                      <TriggersTable rows={detailStore.triggeredTickets} />
                    </>
                  )}
                </div>
              </ResizeGroupPanel>
            </div>
          )}
        </>
      )}
    </div>
  );
});
