import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import {
  AmazonStatus,
  CompactInfo,
  Dropdown,
  MultiLineInput,
  TextField,
  TypeaheadDropdown,
  Typography,
  UploadAttachment,
} from '@/components';
import { AttachmentArea } from '@/components/UploadAttachment';
import { ObjectType, PriorityOptions } from '@/utils/types';
import { SHORT_DATE_FORMAT, formatLocalDateTime, getFileSizeError, getHyperText } from '@/utils';
import { StatusSelect } from '../StatusSelect';
import { userStore } from '@/store';
import { useMainStore } from '../../stores/useMainStore';
import { ATTACHMENTS_FILED } from '@/utils/constants';

import styles from './Information.module.css';

const PAGE_NAME = 'InformationPanel';

export const InformationPanel = observer(({ variant, data, updateData, showError }) => {
  const {
    title,
    priority,
    description,
    remainingWork,
    ownedBy,
    assignedTo,
    state,
    externalStatus,
    objectType,
    createdByName,
    estimatedCompletion,
    buildError,
  } = data;
  const { detailStore } = useMainStore();
  const hyperText = useMemo(() => getHyperText(description || ''), [description]);
  const isQuestion = variant === ObjectType.question;
  const isEditing = detailStore.isEditing;

  return (
    <div id={`${PAGE_NAME}_div`} className={styles.informationWrapper}>
      <div className={styles.informationRow}>
        <CompactInfo label="Status">
          <StatusSelect
            id={`${PAGE_NAME}_statusSelect`}
            value={state}
            options={userStore.states}
            onChange={(value) => updateData({ state: value })}
            isDarkTheme
            disabled={!isEditing}
          />
        </CompactInfo>
        {objectType === ObjectType.case && externalStatus && (
          <CompactInfo label="ExternalStatus">
            <AmazonStatus id={`${PAGE_NAME}_externalStatusSelect`} value={externalStatus} />
          </CompactInfo>
        )}
        <CompactInfo label="Creator">
          <Typography variant="body2">{createdByName}</Typography>
        </CompactInfo>
        <CompactInfo label="Owner">
          <TypeaheadDropdown
            id={`${PAGE_NAME}_ownerDropdown`}
            value={ownedBy}
            options={detailStore.assignOptions}
            onChange={(value) => updateData({ ownedBy: value })}
            isDarkTheme
            disabled={!isEditing}
            classes={{ container: styles.fixedWidth }}
          />
        </CompactInfo>
        <CompactInfo label="Assigned to">
          <TypeaheadDropdown
            id={`${PAGE_NAME}_assignDropdown`}
            value={assignedTo}
            options={detailStore.assignOptions}
            onChange={(value) => updateData({ assignedTo: value })}
            isDarkTheme
            disabled={!isEditing}
            classes={{ container: styles.fixedWidth }}
          />
        </CompactInfo>
        <CompactInfo label="Estimated Bv">
          <Typography variant="body2">
            {formatLocalDateTime(estimatedCompletion, SHORT_DATE_FORMAT)}
          </Typography>
        </CompactInfo>
        {!isQuestion && (
          <>
            <CompactInfo label="Priority">
              <Dropdown
                id={`${PAGE_NAME}_priority_dropdown`}
                placeholder="Select Priority"
                value={priority}
                options={PriorityOptions}
                onChange={(e) => updateData({ priority: Number(e.target.value) })}
                disabled={!isEditing}
                sx={{ width: '92px' }}
              />
            </CompactInfo>
            <CompactInfo label="Quantity">
              {!isEditing ? (
                <Typography variant="body2">{remainingWork}</Typography>
              ) : (
                <TextField
                  type="number"
                  id={`${PAGE_NAME}_quantity_textfield`}
                  placeholder="Input Quantity"
                  error={showError && !remainingWork}
                  value={remainingWork}
                  onChange={(e) => updateData({ remainingWork: Number(e.target.value) })}
                  sx={{ width: 72 }}
                  disabled={!isEditing}
                />
              )}
            </CompactInfo>
          </>
        )}
      </div>

      {isEditing && (
        <CompactInfo label="Title*" leftIndent>
          <MultiLineInput
            id={`${PAGE_NAME}_title_multiLineInput`}
            size="small"
            error={showError && !title}
            placeholder="Provide a title of the task"
            value={title}
            maxLength={160}
            onChange={(value) => updateData({ title: value })}
            style={{ flex: 1 }}
          />
        </CompactInfo>
      )}
      <CompactInfo label="Description" leftIndent>
        {!isEditing && description && (
          <Typography variant="body2" multiline sx={{ flex: 1 }}>
            <div dangerouslySetInnerHTML={{ __html: hyperText }} />
          </Typography>
        )}
        {isEditing && (
          <MultiLineInput
            id={`${PAGE_NAME}_description_multiLineInput`}
            placeholder="Provide a description of the task"
            value={description}
            size="small"
            onChange={(value) => updateData({ description: value })}
            style={{ flex: 1 }}
          />
        )}
      </CompactInfo>
      {buildError && (
        <CompactInfo label="Build Error" leftIndent>
          <Typography variant="body2" className={styles.fullWidth}>
            {buildError}
          </Typography>
        </CompactInfo>
      )}
      {isEditing && (
        <div id={`${PAGE_NAME}_uploadAttachment_container`}>
          <Typography variant="subtitle2" className={styles.uploadTitle}>
            Upload Attachment(s)
          </Typography>
          <UploadAttachment
            contentClassName={styles.uploader}
            files={data.attachments}
            error={getFileSizeError(data.attachments)}
            onUpdateFile={(files) => updateData({ [ATTACHMENTS_FILED]: files })}
          />
        </div>
      )}
      {!isEditing && data.attachments?.length > 0 && (
        <div>
          <Typography variant="subtitle2">Attachment(s)</Typography>

          <AttachmentArea files={data.attachments} />
        </div>
      )}
    </div>
  );
});
