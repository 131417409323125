import { omit } from 'lodash';
import { Config } from '../config';
import { parseUser } from './parseUtils';
import * as fetchWithAuth from './fetchWithAuth';
import { DEFAULT_BRANDING_INFO, FetchOptions } from './constants';
import { userStore } from '../store';
import { getPlatformValue } from '../utils/staticForm';
import { formatLocalDateTime, makeChildrenTree } from '../utils';

export const getOrganizationUsers = async (organizationId, fullyHydrated = true) => {
  if (!organizationId) return [];

  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/${organizationId}/users?fullyHydrated=${fullyHydrated}`)
    .then((resp) => resp.items.map(parseUser));
};

function getOrgPlatformList(organizationInfo) {
  if (!userStore.platformTypes?.length) return {};
  const logins = organizationInfo?.profile?.logins ?? [];
  const orgPlatformLabels = logins
    .map(
      ({ platform }) => userStore.platformTypes.find(({ id }) => id === platform)?.name ?? platform,
    )
    .join(', ');
  const orgPlatformNames = logins.map(({ platform }) => getPlatformValue(platform)).join(', ');
  return { orgPlatformLabels, orgPlatformNames };
}

function parseOrganization(obj, grandParentPath = []) {
  const path = obj.path
    .split('/')
    .filter((id) => id)
    .map(Number)
    .filter((id) => !grandParentPath.includes(id));
  const branding = obj.profile?.branding;
  const pathByName = obj.pathByName;

  return {
    id: obj.id,
    name: obj.organization,
    parentId: obj.parentId,
    grandParentPath,
    path,
    createdOn: formatLocalDateTime(obj.createdOn),
    isActive: obj.isActive,
    children: [],
    pathByName: obj.pathByName,
    parentPath: pathByName && pathByName.split('/').slice(0, -2).join('/'),
    ...getOrgPlatformList(obj),
    profile: {
      ...obj.profile,
      branding: {
        alias: branding?.alias ?? '',
        logoUrl: branding?.logoUrl || DEFAULT_BRANDING_INFO.logoUrl,
        mailFrom: branding?.eMail ?? '',
        helpUrl: branding?.supportUrl || DEFAULT_BRANDING_INFO.helpUrl,
      },
    },
  };
}

export const getOrganization = async (orgId) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/${orgId}`)
    .then((resp) => omit(parseOrganization(resp), 'children'));
};

export const createOrganization = async (orgInfo) => {
  return fetchWithAuth.post(`${Config.NEW_API}/Organizations`, orgInfo).then((resp) => resp);
};

export const updateOrganization = async (orgId, orgInfo) => {
  return fetchWithAuth
    .put(`${Config.NEW_API}/Organizations/${orgId}`, orgInfo, FetchOptions.status)
    .then((resp) => resp);
};

export const getInternalOrganizations = async () => {
  try {
    const response = await fetchWithAuth.get(`${Config.NEW_API}/Internal/Organizations`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const transformInternalOrgs = (internalOrgs) => {
  const items = Object.keys(internalOrgs).map((path) => {
    const ids = path
      .split('/')
      .filter((id) => id)
      .map(Number);
    const id = ids[ids.length - 1];
    const parentId = ids.length > 1 ? ids[ids.length - 2] : null;

    return {
      id: id,
      parentId: parentId,
      organization: internalOrgs[path],
      path: path,
      profile: {},
      createdOn: '',
      isActive: true,
      pathByName: path.replace(/\d+/g, (match) => internalOrgs[`/${match}/`] || ''),
    };
  });

  return {
    count: items.length,
    items: items,
  };
};

export const getOrganizationFullTree = async () => {
  try {
    const internalOrgs = await getInternalOrganizations();

    const transformedOrgs = transformInternalOrgs(internalOrgs);

    const path = transformedOrgs.items[0]?.path
      .split('/')
      .filter((id) => id)
      .map(Number);
    const parentPath = path.filter((id) => id !== transformedOrgs.items[0].id);
    const arrangedAccounts = transformedOrgs.items.map((item) =>
      parseOrganization(item, parentPath),
    );
    const orgsTree = makeChildrenTree(arrangedAccounts);

    const result = {
      parent: parseOrganization(transformedOrgs.items[0], parentPath),
      children: orgsTree.children,
      arrangedAccounts: arrangedAccounts.filter(({ id }) => !orgsTree.inactivatedIds.includes(id)),
    };

    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getAssigners = async (orgId) => {
  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/${orgId}/assignables`)
    .then((resp) => resp.items.map(parseUser));
};

export const getPlatformConfiguration = async (userName, platform) => {
  if (!userName || !platform) return {};
  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/platform/login?userName=${userName}&platform=${platform}`)
    .then((resp) => resp.configuration)
    .catch(() => ({}));
};

export const getOtpCode = async (otpKey) => {
  if (!otpKey) return '';
  return fetchWithAuth
    .get(`${Config.NEW_API}/Organizations/platform/get_otp_code?otpKey=${otpKey}`)
    .then((resp) => resp)
    .catch(() => '');
};

export const savePlatformLogins = async (logins) => {
  return fetchWithAuth
    .post(`${Config.NEW_API}/Organizations/platform/logins`, logins, FetchOptions.status)
    .then((resp) => resp);
};
