export const MAX_QUESTION_LENGTH = 250;
export const DEFAULT_ITEM_HEIGHT = 28;
export const PLATFORM_ITEM_WIDTH = 312;

export const TicketTypes = 'TicketTypes';
export const TicketStates = 'TicketStates';

export const PLATFORM_TYPE_FIELDS = ['Platform', 'PlatformType'];
export const WORKFLOW_TYPE_FIELD = '$type';

export const ATTACHMENTS_FILED = 'attachments';

export const ACCEPT_TEMPLATE_FILES =
  'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const WARNING_COLOR = '#D7A100';

export const OPERATION_TYPES = {
  new: 'New',
  update: 'Update',
};
export const ORG_OPERATION_OPTIONS = [
  {
    label: 'Create organization',
    value: OPERATION_TYPES.new,
  },
  {
    label: 'Update organization',
    value: OPERATION_TYPES.update,
  },
];

export const DEFAULT_KEYWORD_INFO = {
  Asin: '',
  ProductType: '',
};

export const CASE_OPERATION_TYPES = {
  create: 'Create',
  get: 'Get',
};

export const CASE_OPERATION_OPTIONS = [
  {
    label: 'Track a case',
    value: CASE_OPERATION_TYPES.get,
  },
  {
    label: 'Create a case',
    value: CASE_OPERATION_TYPES.create,
  },
];

export const CREATE_CASE_TYPES = {
  updateProduct: 'UpdatePdp',
  updateVariation: 'UpdateVariation',
  removeVariation: 'RemoveVariation',
};

export const CREATE_CASE_OPTIONS = [
  {
    value: CREATE_CASE_TYPES.updateProduct,
    label: 'Update Product Detail Page',
  },
  {
    value: CREATE_CASE_TYPES.updateVariation,
    label: 'Update Product Variation',
  },
  {
    value: CREATE_CASE_TYPES.removeVariation,
    label: 'Remove Product Variation',
  },
];

export const FilterType = {
  searchType: 'searchType',
  captcha: 'captcha',
  platform: 'platform',
  account: 'account',
  status: 'status',
  owner: 'owner',
  assign: 'assign',
  service: 'service',
  type: 'isRequest',
  viewBy: 'viewBy',
  createdAfter: 'createdAfter',
  updatedAfter: 'updatedAfter',
  automation: 'automation',
  organizationId: 'organizationId',
  lastUpdated: 'lastUpdated',
  activeChildren: 'activeChildren',
};

export const loadingImg = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const SETTINGS_MENU = {
  organizationPage: 'organizations',
  usersPage: 'users',
};

export const MENU_LIST = [
  { label: 'Organizations', value: SETTINGS_MENU.organizationPage },
  { label: 'Users', value: SETTINGS_MENU.usersPage },
];

export const CONFIG_TABS = {
  form: 'Form',
  json: 'Json',
  configuration: 'Configuration',
  results: 'Results',
};

export const TICKET_TABS = {
  details: 'Details ',
  discussions: 'Discussions',
  configuration: 'Configuration',
  schedules: 'Schedules',
};

export const MAX_QUERY_LENGTH = 2048;
export const MAX_QUANTITY = 100000;

export const AUTOPILOT_APP_LINK = 'https://autopilot.profitero.com';
export const FeedbackLink = 'https://form.typeform.com/to/IOW0VuJj';

export const PAGE_MODE = {
  none: 0,
  loading: 1,
  editing: 2,
  creating: 3,
};

export const DEFAULT_ORGANIZATION = {
  name: 'New Organization',
  isActive: true,
  profile: {
    branding: { isLogoChanged: false },
    logins: [],
    eventSubscriptions: [],
    settings: {},
    services: [],
  },
};

export const DEFAULT_PRODUCT_INFO = {
  asin: '',
  title: '',
  brand: '',
  description: '',
  isValid: false,
};

export const PRODUCT_PROPERTIES = [
  [
    {
      label: 'Bullet1',
      key: 'Bullet1',
    },
    {
      label: 'Bullet2',
      key: 'Bullet2',
    },
    {
      label: 'Bullet3',
      key: 'Bullet3',
    },
    {
      label: 'Bullet4',
      key: 'Bullet4',
    },
    {
      label: 'Bullet5',
      key: 'Bullet5',
    },
  ],
  [
    {
      label: 'ImageCount',
      key: 'ImageCount',
    },
    {
      label: 'Category',
      key: 'Category',
    },
    {
      label: 'Variants',
      key: 'Variants',
    },
    {
      label: 'Aplus',
      key: 'Aplus',
    },
    {
      label: 'BuyBoxWinner',
      key: 'BuyBoxWinner',
    },
    {
      label: 'BuyBoxPrice',
      key: 'BuyBoxPrice',
    },
    {
      label: 'BuyBoxShippingFee',
      key: 'BuyBoxShippingFee',
    },
    {
      label: 'Domain',
      key: 'Domain',
    },
    {
      label: 'Keywords',
      key: 'Keywords',
    },
  ],
];

export const SearchTypes = {
  name: 'name',
  path: 'path',
};

const OrgsColumnType = {
  id: 'id',
  name: 'name',
  platforms: 'orgPlatformLabels',
  createdOn: 'createdOn',
  captcha: 'captcha',
};

export const OrganizationFilters = {
  [FilterType.searchType]: {
    key: OrgsColumnType.name,
    label: 'Search by',
    values: [
      { value: SearchTypes.name, label: 'Search by name' },
      { value: SearchTypes.path, label: 'Search by path' },
    ],
    selectedValues: [SearchTypes.path],
  },
  [FilterType.platform]: {
    key: OrgsColumnType.platforms,
    label: 'Platform',
    values: [],
    selectedValues: [],
  },
  [FilterType.captcha]: {
    key: OrgsColumnType.captcha,
    label: 'Captcha',
    values: [{ value: 'captcha', label: 'Is Captcha' }],
    selectedValues: [],
  },
};

export const DEFAULT_SCHEDULE = {
  cron: '0 13 * * 1',
  ticketId: '',
  paused: false,
};

export const DEFAULT_CRONS = [
  { value: '0 13 * * 1', label: 'Every Monday' },
  { value: '0 13 * * 1,4', label: 'Every Monday and Thursday' },
  { value: '0 13 * * 1,3,5', label: 'Every Monday, Wednesday, and Friday' },
  { value: '0 13 * * *', label: 'Daily' },
  { value: '0 13 6 * *', label: 'On the 6th of every month' },
  { value: '0 13 * * 2', label: 'Every Tuesday' },
  { value: '0 13 * * 3', label: 'Every Wednesday' },
  { value: '0 13 * * 4', label: 'Every Thursday' },
  { value: '0 13 * * 5', label: 'Every Friday' },
  { value: '0 13 11 * *', label: 'On the 11th each month' },
  { value: '0 13 12 * *', label: 'On the 12th of the month' },
  { value: '0 13 15 * *', label: 'On the 15th of the month' },
  { value: '0 13 27 * *', label: 'On the 27th each month' },
  { value: '0 13 28 * *', label: 'On the 28th each month' },
  { value: '0 13 26 * *', label: 'On the 26th each month' },
];

export const SCHEDULE_MODE = {
  none: '',
  edit: 'edit',
  create: 'create',
};
