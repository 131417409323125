import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useMainStore } from '../stores/useMainStore';
import { Filter } from './Filter';
import { TaskLogTableRow } from './TaskLogTableRow';
import { EmptyLogView } from './EmptyLogView';
import { useStyles } from './styles';
import {
  EmptyFilterView,
  LoadingIndicator,
  Pagination,
  ResizeGroupPanel,
  TablePagination,
  Typography,
} from '@/components';
import { ColumnType } from '@/utils/types';
import { DetailTicketContent } from './DetailTicketContent';
import { useTicketDetails } from '../hooks/useTicketDetails';

const PAGE_NAME = 'TaskLog';

const TaskLog = () => {
  const { accountStore, taskLogStore, detailStore, tableStore, filterStore } = useMainStore();
  const styles = useStyles();
  const tableRef = useRef(null);
  const [isScrollVisible, setIsScrollVisible] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(0);

  useEffect(() => {
    if (tableRef.current) {
      if (tableRef.current.scrollHeight > tableRef.current.clientHeight + 10) {
        setIsScrollVisible(true);
      } else {
        setIsScrollVisible(false);
      }
    }
  }, [taskLogStore.parsedLogs]);

  useTicketDetails(taskLogStore, detailStore, selectedTicketId);

  const isLoading = taskLogStore.isLoading || accountStore.isLoading;

  if (
    !isLoading &&
    taskLogStore.parsedLogs?.length === 0 &&
    filterStore.allFilteredCount === 0 &&
    !filterStore.search
  ) {
    return <EmptyLogView />;
  }

  return (
    <div className={styles.content} id={`${PAGE_NAME}_container`}>
      <div className={styles.container} id={`${PAGE_NAME}_contentContainer`}>
        {accountStore.isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Filter />
            <ResizeGroupPanel
              direction="vertical"
              firstPanel={
                <>
                  {taskLogStore.isLoading && <LoadingIndicator />}
                  {!isLoading && (
                    <div
                      className={cx(styles.tableBlock, {
                        [styles.hidden]: detailStore.isFullScreen,
                      })}
                      id={`${PAGE_NAME}_tableBlock`}
                    >
                      {taskLogStore.parsedLogs.length === 0 &&
                      (filterStore.allFilteredCount > 0 || filterStore.search) ? (
                        <EmptyFilterView
                          noFilterResult={filterStore.allFilteredCount > 0}
                          onClick={() =>
                            filterStore.allFilteredCount > 0
                              ? filterStore.clearFilter()
                              : filterStore.setSearch('')
                          }
                        />
                      ) : (
                        <>
                          <TableContainer className={styles.tableContainer} ref={tableRef}>
                            <Table
                              stickyHeader
                              size="small"
                              style={{ paddingRight: isScrollVisible ? 0 : 8 }}
                              id={`${PAGE_NAME}_table`}
                            >
                              <TableHead id={`${PAGE_NAME}_table_head`}>
                                <TableRow>
                                  {tableStore.headerColumns.map(
                                    ({ label, align, sortKey, key, width }, idx) => (
                                      <TableCell
                                        key={label}
                                        align={align}
                                        className={cx(styles.headCell, {
                                          [styles.headFirstCell]: idx === 0,
                                          [styles.headCellLast]:
                                            idx === tableStore.headerColumns.length - 1,
                                        })}
                                        style={{ minWidth: width }}
                                      >
                                        {sortKey ? (
                                          <TableSortLabel
                                            active={tableStore.isActiveSort(sortKey)}
                                            direction={tableStore.getSortDirection(sortKey)}
                                            IconComponent={ArrowDropDownIcon}
                                            classes={{ icon: styles.sortIcon }}
                                            onClick={() => tableStore.toggleSort(sortKey)}
                                          >
                                            <Typography variant="button-small">{label}</Typography>
                                          </TableSortLabel>
                                        ) : (
                                          <Typography variant="button-small">{label}</Typography>
                                        )}
                                      </TableCell>
                                    ),
                                  )}
                                </TableRow>
                              </TableHead>

                              <TableBody id={`${PAGE_NAME}_table_body`}>
                                {taskLogStore.parsedLogs.map((log, index) => (
                                  <TaskLogTableRow
                                    key={log[ColumnType.id]}
                                    log={log}
                                    isLastRow={index === taskLogStore.parsedLogs.length - 1}
                                    setSelectedTicketId={setSelectedTicketId}
                                  />
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <div className={styles.pagination}>
                            <TablePagination
                              label="View Per Page"
                              value={tableStore.rowsPerPage}
                              id={`${PAGE_NAME}_paginationDropdown`}
                              options={[10, 20, 50, 100, 1000]}
                              onChangeRowsPerPage={(v) => tableStore.setRowsPerPage(v)}
                            />
                            <Pagination
                              count={taskLogStore.pageCount}
                              page={tableStore.pageNumber}
                              onChange={(e, v) => tableStore.setPageNumber(v)}
                              id={`${PAGE_NAME}_pagination`}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              }
              showLastPanel={detailStore.isShowTicket}
            >
              <DetailTicketContent />
            </ResizeGroupPanel>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(TaskLog);
