import { useEffect } from 'react';
import { disableGlobalCursorStyles, Panel, PanelGroup } from 'react-resizable-panels';

import { ResizeHandle } from './ResizeHandle';
import { RESIZE_SAVE_KEY } from '@/utils';

import styles from './styles.module.css';

export default function ResizeGroupPanel({
  saveId = RESIZE_SAVE_KEY,
  direction = 'vertical',
  firstPanel = null,
  middlePanel = null,
  showFirstPanel = true,
  showLastPanel = true,
  className = '',
  children,
}) {
  const resizeHandleClass =
    direction === 'vertical' ? styles.verticalResizeHandle : styles.horizontalResizeHandle;

  useEffect(() => disableGlobalCursorStyles(), []);

  return (
    <PanelGroup autoSaveId={saveId} direction={direction} id={saveId} className={className}>
      {showFirstPanel && (
        <>
          <Panel className={styles.Panel} collapsible order={1}>
            {firstPanel}
          </Panel>
          <ResizeHandle id={saveId} className={resizeHandleClass} />
        </>
      )}
      {Boolean(middlePanel) && (
        <>
          <Panel className={styles.Panel} collapsible order={2}>
            {middlePanel}
          </Panel>
          <ResizeHandle id="middleHandle" className={resizeHandleClass} />
        </>
      )}
      {showLastPanel && (
        <Panel className={styles.Panel} collapsible defaultSize={70} order={3}>
          {children}
        </Panel>
      )}
    </PanelGroup>
  );
}
