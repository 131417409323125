import { orderBy, isEmpty } from 'lodash';

import { userStore } from '../store';
import {
  CASE_OPERATION_TYPES,
  CREATE_CASE_TYPES,
  DEFAULT_PRODUCT_INFO,
  PRODUCT_PROPERTIES,
} from '../utils/constants';
import {
  AmazonBrandRegistry,
  AmazonSellerCentral,
  AmazonVendorCentral,
  GeneralPlatform,
  GoogleAds,
} from '../components/PlatformLogins';
import { WORKFLOW_CONFIGS } from './workflows';

export const PLATFORMS = {
  none: 0,
  amazonSellerCentral: 1,
  amazonVendorCentral: 2,
  amazonBrandRegistry: 4,
  googleAds: 6,
};

const PLATFORM_MAPS = {
  [PLATFORMS.amazonSellerCentral]: AmazonSellerCentral,
  [PLATFORMS.amazonVendorCentral]: AmazonVendorCentral,
  [PLATFORMS.amazonBrandRegistry]: AmazonBrandRegistry,
  [PLATFORMS.googleAds]: GoogleAds,
};

const PLATFORM_VALUES = {
  [PLATFORMS.amazonSellerCentral]: 'SC',
  [PLATFORMS.amazonVendorCentral]: 'VC',
  [PLATFORMS.amazonBrandRegistry]: 'BR',
  [PLATFORMS.googleAds]: 'GA',
};

export function getPlatformLoginForm(platformId) {
  return {
    name: userStore.platformTypes.find(({ id }) => id === platformId)?.name,
    form: PLATFORM_MAPS[platformId] || GeneralPlatform,
  };
}

export function getPlatformName(platformId) {
  return userStore.platformTypes.find(({ id }) => id === platformId)?.name || 'None';
}

export function getPlatformValue(platformId) {
  return PLATFORM_VALUES[platformId] || 'NA';
}

export const PlatformLoginValidator = ({ Username, configuration }) =>
  Boolean(Username && configuration.Account && configuration.Marketplace);

export const GeneralLoginValidator = ({ Username }) => Boolean(Username);

const LOGIN_VALIDATORS = {
  [PLATFORMS.amazonSellerCentral]: PlatformLoginValidator,
  [PLATFORMS.amazonVendorCentral]: PlatformLoginValidator,
  [PLATFORMS.amazonBrandRegistry]: PlatformLoginValidator,
  [PLATFORMS.googleAds]: GeneralLoginValidator,
};

const sortWorkflows = (workflowsList) => {
  const sortedWorkflows = orderBy(workflowsList, 'name', 'asc');
  return sortedWorkflows.map((category) => ({
    ...category,
    children: category.children?.length > 0 ? sortWorkflows(category.children) : [],
  }));
};

const makeFullTree = (workflowsList) => {
  const result = { children: [] };
  workflowsList.forEach((workflow) => {
    let currentCategory = result;
    workflow.path.forEach((name, index) => {
      const nextCategory = currentCategory.children.find((item) => item.name === name);
      if (!nextCategory) {
        const newCategory = { id: name, name, children: [], isExpanded: true };
        currentCategory.children.push(newCategory);
        currentCategory = newCategory;
      } else {
        currentCategory = nextCategory;
      }
      if (index === workflow.path.length - 1) currentCategory.children.push(workflow);
    });
  });
  return sortWorkflows(result.children);
};

export const getWorkflowConfigList = () => {
  const workflowConfigList = Object.entries(WORKFLOW_CONFIGS).map(([workflow, config]) => ({
    ...config,
    workflow,
    displayName: config.title,
    name: `${workflow}Config`,
    path: [config.category],
  }));
  const workflowTreeList = makeFullTree(workflowConfigList);
  return { workflowConfigList, workflowTreeList };
};

export function getValidPlatformLogin(platformLogin) {
  const getValidator = LOGIN_VALIDATORS[platformLogin.platform] || GeneralLoginValidator;
  return getValidator(platformLogin);
}

export function getInitialProperties(defaultValue) {
  if (!defaultValue) return [];
  return Object.entries(defaultValue)
    .filter(([key]) => !Object.keys(DEFAULT_PRODUCT_INFO).includes(key))
    .map(([key, value]) => ({
      key,
      value,
      label: PRODUCT_PROPERTIES.find(({ key: filedName }) => filedName === key)?.label ?? key,
    }));
}

export function getInitialValue(defaultValue) {
  return Object.entries(defaultValue ?? {})
    .filter(([key]) => !Object.keys(DEFAULT_PRODUCT_INFO).includes(key))
    .map(([key, value]) => ({
      key,
      value,
      label: PRODUCT_PROPERTIES.find(({ key: filedName }) => filedName === key)?.label ?? key,
    }));
}

const isEmptyValue = (value) =>
  (value !== 0 && !value) || (typeof value === 'object' && isEmpty(value));

export function getWorkflowFiles(workflowName, config) {
  if (workflowName && !isEmptyValue(config)) {
    if (workflowName.includes('Case')) {
      const filename = config.Create?.UpdatePdp?.Attachment;
      if (filename) return [{ id: `${filename}`, name: filename, hasWorkflowContent: true }];
    }
    const attachments = WORKFLOW_CONFIGS[workflowName]?.attachments;
    return attachments?.reduce((acc, attachedNameField) => {
      const filename = config[attachedNameField];
      if (!filename) return acc;
      const fieldAttachment = Array.isArray(filename)
        ? filename
            .filter((name) => name)
            .map((name) => ({ id: `${name}`, name, hasWorkflowContent: true }))
        : [
            {
              id: `${filename}`,
              name: filename,
              hasWorkflowContent: true,
            },
          ];
      return acc.concat(fieldAttachment);
    }, []);
  }
  return null;
}

export function getWorkflowAttachments(workflowName, config) {
  if (workflowName && !isEmptyValue(config)) {
    if (workflowName.includes('Case')) {
      const filename = config.Create?.UpdatePdp?.Attachment;
      if (filename) return [{ id: `${filename}`, name: filename, hasWorkflowContent: true }];
    }
    const attachments = WORKFLOW_CONFIGS[workflowName]?.attachments;
    return attachments?.reduce((acc, attachedNameField) => {
      const filename = config[attachedNameField];
      return {
        ...acc,
        [attachedNameField]: filename && { name: filename },
      };
    }, {});
  }
  return null;
}

export function getInitialCaseValue(config) {
  const getCaseInfo = config?.[CASE_OPERATION_TYPES.get];
  const createCaseInfo = config?.[CASE_OPERATION_TYPES.create];
  const caseOperation = createCaseInfo ? CASE_OPERATION_TYPES.create : CASE_OPERATION_TYPES.get;
  const createType = createCaseInfo?.[CREATE_CASE_TYPES.updateProduct]
    ? CREATE_CASE_TYPES.updateProduct
    : createCaseInfo?.[CREATE_CASE_TYPES.updateVariation]
    ? CREATE_CASE_TYPES.updateVariation
    : CREATE_CASE_TYPES.removeVariation;

  const createCaseValue = createCaseInfo?.[createType] ?? {};

  const value = {
    caseId: getCaseInfo?.CaseId ?? '',
    business: createCaseValue?.Business ?? '',
    description: createCaseValue?.Description ?? '',
    asins: createCaseValue?.Asins ?? '',
    subject: createCaseValue?.Subject ?? '',
    attachment: createCaseValue?.Attachment ?? '',
  };

  return {
    caseOperation,
    createType,
    value,
  };
}
