import { makeObservable, computed } from 'mobx';
import { FilterStore } from './FilterStore';
import { TableStore } from './TableStore';
import { TaskLogStore } from './TaskLogStore';
import { accountStore } from '@/store/AccountStore';
import { userStore } from '@/store';
import { DetailContentStore } from './DetailContentStore';
import { WorkflowStore } from './WorkflowStore';

export class MainStore {
  constructor() {
    makeObservable(this, {
      isLoading: computed,
    });

    this.accountStore = accountStore;
    this.filterStore = new FilterStore(this.accountStore);
    this.tableStore = new TableStore(this.accountStore, this.filterStore);
    this.taskLogStore = new TaskLogStore(accountStore, this.tableStore, this.filterStore);
    this.detailStore = new DetailContentStore(this.taskLogStore);
    this.workflowStore = new WorkflowStore(this.detailStore);
    this.routerStore = userStore.routerStore;
  }

  get isLoading() {
    return this.taskLogStore.isLoading;
  }

  dispose() {
    this.accountStore.dispose();
    this.filterStore.dispose();
    this.tableStore.dispose();
    this.taskLogStore.dispose();
  }
}
