import { orderBy, trim } from 'lodash';
import { Config } from '../config';
import { userStore } from '../store';
import {
  getAssignFromId,
  getStatus,
  convertLocalDateTime,
  getObjectType,
  getAmazonShortenStatus,
  formatLocalDateTime,
} from '../utils';
import { AssignType, AutomationStatusColors } from '@/utils/types';
import * as fetchWithAuth from './fetchWithAuth';
import { parseTaskLogFromResponse } from './parseUtils';

function parseDetailListFromResponse(obj) {
  return {
    ticketId: obj.id,
    organizationId: obj.organizationId,
    title: obj.title || '',
    ticketPath: (obj.path ?? '')
      .split('/')
      .filter((id) => id)
      .map(Number),
    state: getStatus(obj.state),
    externalStatus: getAmazonShortenStatus(obj.externalState),
    objectType: getObjectType(obj),
    serviceType: obj.service || obj.workflow,
    assignedToName: trim(obj.assignedToName || ''),
    ownedByName: trim(obj.ownedByName || obj.createdByName || ''),
    assignedTo: obj.assignedTo,
    openQuestions: obj.openQuestions,
  };
}

export function parseCaseMessage(message, assignOptions) {
  const assign = getAssignFromId(message.createdBy, assignOptions);
  const owner = message.externalBy || assign?.label || '';
  let type = assign?.type || AssignType.lightPurple;
  const name = owner.toLowerCase();
  if (message.externalBy) {
    if (name.includes('amazon')) type = AssignType.amazon;
    if (name.includes('helpdesk')) type = AssignType.helpdesk;
    if (name.includes('workflow')) type = AssignType.workflow;
  }

  const lastUpdated = message.externalOn ?? message.lastUpdated;

  return { ...message, assign: { name: owner, type }, lastUpdated };
}

export const getTaskTree = async (ticketId) => {
  return await fetchWithAuth
    .get(`${Config.NEW_API}/Ticket/${ticketId}/tree`)
    .then((resp) => orderBy(resp.map(parseDetailListFromResponse), 'ticketPath', 'asc'));
};

export const getTicketLogTree = async (ticketId) => {
  return await fetchWithAuth
    .get(`${Config.NEW_API}/Ticket/${ticketId}/tree?fullyHydrated=true`)
    .then((resp) => orderBy(resp.map(parseTaskLogFromResponse), 'ticketPath', 'asc'));
};

function parseTicketFromResponse(obj) {
  const discussions = orderBy(
    (obj.discussions ?? []).filter((item) => !(item.message || '').includes('[TicketId=')),
    [(o) => convertLocalDateTime(o.externalOn ?? o.createdOn)],
    'desc',
  );
  const attachedDiscussion = (obj.discussions ?? []).reduce(
    (result, item) =>
      (item.message || '').includes('[TicketId=') && (!result || item.attachments) ? item : result,
    null,
  );
  const attachments = (attachedDiscussion?.attachments || []).map((attachment) => ({
    id: attachment.id,
    name: attachment.attachment,
    url: attachment.uri,
    size: attachment.size,
  }));
  const ticketPath = (obj.path ?? '').split('/').filter((id) => id);
  const originConfig = obj.config;
  const config = JSON.parse(originConfig);
  const buildError = obj.buildError;
  const { automation, automationTime, buildId } = obj;

  return {
    id: obj.id,
    ticketId: obj.id,
    requestId: ticketPath?.[0],
    parentId: obj.parentId,
    organization: obj.organization,
    organizationId: obj.organizationId,
    automation: automation
      ? {
          name: automation,
          color: AutomationStatusColors[automation] || AutomationStatusColors.none,
          automationTime:
            automationTime && formatLocalDateTime(convertLocalDateTime(automationTime)),
          buildId,
        }
      : null,
    buildError,
    config,
    originConfig,
    ticketPath,
    assignedTo: Number(obj.assignedTo),
    assignedToName: (obj.assignedToName || '').trim(),
    ownedBy: Number(obj.ownedBy),
    ownedByName: (obj.ownedByName || '').trim(),
    title: obj.title,
    state: getStatus(obj.state),
    externalId: obj.externalId,
    externalStatus: getAmazonShortenStatus(obj.externalState),
    objectType: getObjectType(obj),
    description: obj.description,
    workflow: obj.service || obj.workflow,
    priority: Number(obj.priority),
    remainingWork: Number(obj.quantity),
    createdById: Number(obj.createdBy),
    createdByName: (obj.createdByName || '').trim(),
    createdDate: convertLocalDateTime(obj.createdOn),
    lastUpdated: convertLocalDateTime(obj.updatedOn),
    requestedByDate: convertLocalDateTime(obj.requestedByDate),
    resolutionDate: convertLocalDateTime(obj.updatedOn),
    estimatedCompletion: convertLocalDateTime(obj.estimatedCompletion),
    attachedDiscussionId: attachedDiscussion?.id,
    attachments,
    discussions: discussions.map((discussion) => ({
      id: discussion.id,
      createdBy: Number(discussion.createdBy),
      createdDate: convertLocalDateTime(discussion.createdOn),
      lastUpdated: convertLocalDateTime(discussion.updatedOn ?? discussion.createdOn),
      externalBy: discussion.externalBy,
      externalOn: discussion.externalOn && convertLocalDateTime(discussion.externalOn),
      text: discussion.message || '',
      attachments: (discussion.attachments || []).map((attachment) => ({
        id: attachment.id,
        name: attachment.attachment,
        url: attachment.uri,
      })),
    })),
    caseMessages: discussions,
    isPinned: userStore.currentUser.pinnedTickets.includes(obj.ticketId),
  };
}

export const getTicket = async (ticketId) => {
  return await fetchWithAuth
    .get(`${Config.NEW_API}/Ticket/${ticketId}`)
    .then((resp) => parseTicketFromResponse(resp));
};
