import { IconButton, Stack } from '@mui/material';

import { CopyOutlineIcon } from '@/assets/icons';
import { PLATFORM_ITEM_WIDTH } from '@/utils/constants';
import TextField from '../TextField';
import InfoRow from '../InfoRow';

export const TextCopyField = ({
  label,
  value,
  helperText,
  error,
  disabled,
  onChange,
  onCopyClick,
}) => {
  return (
    <Stack direction="row" alignItems="center" width={PLATFORM_ITEM_WIDTH}>
      <InfoRow label={label} fullWidth>
        <TextField
          value={value ?? ''}
          error={error}
          onChange={onChange}
          size="small"
          disabled={disabled}
          classes={{ flex: 1 }}
          placeholder={helperText}
        />
      </InfoRow>
      <IconButton id="copyButton" size="small" onClick={onCopyClick}>
        <CopyOutlineIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
